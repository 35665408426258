const CVSepalai = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1zIjSCPWuW4jjISURO2rYqMLEvlACrXTLNOzqToe36XE&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVSepalai




