import Project from "../Project/Project";
import customimage1 from "../../assets/img/GiF-WEB-SHOP.gif"
import phonegif from "../../assets/img/1440x3120 to w336x728 exact.gif"
import ecommerceweb from "../../assets/videos/shop-commercial.mp4"
import ecommerceapp from "../../assets/videos/shop-app-commercial.mp4"
import crmweb from "../../assets/videos/crm-commercial.mp4"
import recognizevideo from "../../assets/videos/recognize-commercial.mp4"

import car from "../../assets/img/car.jpg"
import crm from "../../assets/img/crm.jpg"
import recognize from "../../assets/img/recognize-commercial-gif.gif"
import crmgif from "../../assets/img/crm-gif.gif"
import tuto from "../../assets/img/tutorialspoint.jpg"
import mediagram from "../../assets/img/mediagram.jpg"
import shipify from "../../assets/img/shipify.gif"

import booking1 from "../../assets/img/booking1.jpg"
import booking2 from "../../assets/img/booking2.jpg"
import booking3 from "../../assets/img/booking3.jpg"
import medcenter from "../../assets/img/medcenter.jpg"
import movieflix from "../../assets/img/movieflix.jpg"
import landing from "../../assets/img/landing-page-AI.gif"
import gaize from "../../assets/img/gaize.gif"
import aignosis from "../../assets/img/aignosis.gif"
import fainance from "../../assets/img/fainance.gif"
import sumurai from "../../assets/img/sumurai-gif.gif"
const stacks = ["react", "nodejs", "redux", "bootstrap", "tailwindcss","cloudinary","postgresql", "mongodb", "docker", "nextjs", "threejs"];


const ProjectArea = ({compareTag}) => {    
    return(
        <div className="grid grid-cols-1 gap-20">

            
            <Project 
                title="sumurAI"
                stacks={["rust", "nextjs", "tailwindcss", "figma", "typescript","python", "pinecone", "langchain", "llamaindex", "css3", "api", "ollama", "huggingFace", "nvidia", "kubernetes", "docker", "aws", "prometheus", "terraform" ]}
                projectDesc="Summarize YouTube videos and create text & voice scripts"
                device="pc"
                deviceImage={[sumurai]}
                website="https://sumurai.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/sumurai"
                videoSource={"https://player.vimeo.com/video/1035030636?badge=0&amp;&autoplay=1&amp;player_id=0&amp;app_id=58479"} // played
                tags={["website", "ml", "dl", "css", "html", "tailwind", "ai"]}
                compareTag={compareTag}
            />

            <Project 
                title="fAInance"
                stacks={["rust", "nextjs", "tailwindcss", "figma", "typescript","python","postgresql" , "css3", "api", "ollama", "huggingFace", "nvidia", "kubernetes", "docker", "aws", "prometheus" ]}
                projectDesc="Chat with your financial documents | Powered by AI"
                device="pc"
                deviceImage={[fainance]}
                website="https://fainance.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/fainance"
                videoSource={"https://player.vimeo.com/video/1025168894?badge=0&amp;&autoplay=1&amp;player_id=0&amp;app_id=58479"} // played
                tags={["website", "ml", "dl", "css", "html", "tailwind", "ai"]}
                compareTag={compareTag}
            />

            <Project 
                title="AIgnosis"
                stacks={["go", "svelte", "tailwindcss", "figma", "typescript","python", "html5", "css3", "api", "ollama", "huggingFace", "nvidia", "kubernetes", "docker", "aws", "prometheus" ]}
                projectDesc="Medical Diagnosis Search | Powered by Ollama"
                device="pc"
                deviceImage={[aignosis]}
                website="https://aignosis.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/aignosis"
                videoSource={"https://player.vimeo.com/video/1019767296?badge=0&amp;&autoplay=1&amp;player_id=0&amp;app_id=58479"} // played
                tags={["website", "ml", "dl", "css", "html", "tailwind", "ai"]}
                compareTag={compareTag}
            />

            <Project 
                title="gAIze"
                stacks={["go", "react", "tailwindcss", "figma", "typescript", "c" ,"c++", "html5", "css3", "nvidia",  "kubernetes", "docker", "GCP", "aws", "redis", "jenkins"]}
                projectDesc="Gaze Correction Tool for your videos | Powered by AI"
                device="pc"
                deviceImage={[gaize]}
                website="https://gaize.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/gAIze"
                // videoSource={"https://player.vimeo.com/video/1007338212?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479"} stopped
                videoSource={"https://player.vimeo.com/video/1007338212?badge=0&amp;&autoplay=1&amp;player_id=0&amp;app_id=58479"} // played
                tags={["website", "ml", "dl", "css", "html", "tailwind", "ai"]}
                compareTag={compareTag}
            />

            <Project 
                title="Recognize"
                stacks={["django", "react", "nextjs", "redis", "docker", "kubernetes",  "cloudinary", "tensorflow", "pytorch", "postgresql", "tailwindcss", "api", "figma",  "python", "javascript","redux", "aws", "git", "stripe", "jenkins"]}
                projectDesc="Facial Recognition Application | Powered by AI"
                device="pc"
                deviceImage={[recognize]}
                website="https://recognize.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/AI-Facial-recognition"
                videoSource={recognizevideo}
                tags={["website", "django", "react", "python", "redux", "javascript", "ai", "payment"]}
                compareTag={compareTag}
            /> 
            
            <Project 
                title="Landing Page"
                stacks={["nextjs", "express", "figma", "After Effects", "inkscape", "kdenlive", "gimp", "html5", "css3", "tailwindcss", "javascript", "git"]}
                projectDesc="Platform for AI services | Digital advertising & marketting"
                device="pc"
                deviceImage={[landing]}
                website="https://jatolentino.github.io/landing-page-AI/"
                projectUrl="https://github.com/jatolentino/landing-page-AI"
                videoSource={null}
                tags={["website", "marketting", "css", "html", "advertising", "tailwind", "ai"]}
                compareTag={compareTag}
            />

            <Project 
                title="MedGPT"
                stacks={["vuejs", "flask", "postgresql", "nodejs", "tailwindcss",  "figma", "git", "npm", "javascript", "python", "chatgpt", "aws", "stripe", "jenkins", "jira", "bitbucket"]}
                projectDesc="Consult a chatGPT medical doctor expert | For patients"
                device="pc"
                deviceImage={[medcenter]}
                website="https://medcenter-chi.vercel.app/"
                projectUrl="https://github.com/jatolentino/MedGPT"
                videoSource={null}
                tags={["website", "ecommerce", "vuejs", "javascript", "api", "express", "payment", "ai"]}
                compareTag={compareTag}
            />

            <Project 
                title="E-commerce"
                stacks={["mongodb", "express", "react", "nodejs", "redux", "bootstrap", "tailwindcss","cloudinary",  "figma", "git", "npm", "javascript", "aws", "stripe"]}
                projectDesc="Buy and sell products, for vendors and customers"
                device="pc"
                deviceImage={[customimage1]}
                website="https://shop.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/Shop-Ecommerce"
                videoSource={ecommerceweb}
                tags={["website", "ecommerce", "react", "mongodb", "javascript", "api", "next", "express", "payment"]}
                compareTag={compareTag}
            />

            <Project 
                title="E-commerce APP"
                stacks={["flutter", "dart", "java", "figma", "cloudinary", "swift", "gradle", "mongodb", "git", "inkscape", "photoshop", "stripe"]}
                projectDesc="Purchase your favorite products, for customers"
                device="phone"
                deviceImage={[phonegif]}
                website="https://github.com/jatolentino/Shop-Ecommerce-app"
                projectUrl="https://github.com/jatolentino/Shop-Ecommerce-app"
                videoSource={ecommerceapp}
                tags={["mobileapp", "ecommerce", "java", "flutter", "gradle", "swift", "payment"]}
                compareTag={compareTag}
            />

            <Project 
                title="Movieflix"
                stacks={["react", "spring", "nodejs", "redux", "tailwindcss", "python", "javascript", "java", "firebase", "git", "npm",  "figma", "aws", "docker", "kubernetes", "jira", "redis", "jenkins", "bitbucket", "tensorflow"]}
                projectDesc="On-demand video streaming platform | Powered by AI"
                device="pc"
                deviceImage={[movieflix]}
                website="https://movieflix.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/Movieflix"
                videoSource={null}
                tags={["website", "stream", "react", "mongodb", "javascript", "api", "next", "express", "payment"]}
                compareTag={compareTag}
            />

            <Project 
                title="Car Sale"
                stacks={["django", "react", "postgresql", "bootstrap", "api", "supabase",  "python", "javascript", "redux", "aws", "git"]}
                projectDesc="Search for your dream car | For customers & owners"
                device="pc"
                deviceImage={[car]}
                website="https://carsale.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/Car-sale"
                videoSource={""}
                tags={["website","django", "react", "python", "redux", "javascript"]}
                compareTag={compareTag}
            />

            <Project 
                title= "Hotels & Travel APP"
                stacks={["kotlin", "java", "gleam", "grpc", "graphql", "git", "paypal", "stripe" , "terraform", "aws", "bitbucket", "figma"]}
                projectDesc="Hotel Booking system | For users & hotels "
                device="phone"
                deviceImage={[booking1, booking2, booking3]}
                website=""
                projectUrl=""
                videoSource={null}
                tags={["mobileapp", "java", "payment"]}
                compareTag={compareTag}
            />


            <Project 
                title="Shipify"
                stacks={["spring", "react","mongodb", "docker", "kubernetes", "redis", "typescript", "python", "html5", "css3", "api", "figma",  "aws", "git",  "npm", "tensorflow"]}
                projectDesc="Post & Find Shipping Deals | For user & truck owners"
                device="pc"
                deviceImage={[shipify]}
                website="https://github.com/jatolentino/Shipify"
                projectUrl="https://github.com/jatolentino/Shipify"
                videoSource={null}
                tags={["website", "spring boot", "react", "redux", "javascript", "python"]}
                compareTag={compareTag}
            />  

            <Project 
                title="CRM"
                stacks={["django", "react", "postgresql", "tailwindcss", "api", "supabase",  "python", "javascript","redux", "aws", "git", "stripe"]}
                projectDesc="Manage your customers & prospects' info, for businesses"
                device="pc"
                deviceImage={[crmgif]}
                website="https://crm.joseatolentino.com/"
                projectUrl="https://github.com/jatolentino/Django-CRM"
                videoSource={crmweb}
                tags={["website", "django", "react", "python", "redux", "javascript", "payment"]}
                compareTag={compareTag}
            /> 


            <Project 
                title="TutorialsPoint"
                stacks={["go", "nextjs", "redis", "jenkins", "docker", "kubernetes", "cloudinary", "postgresql", "tailwindcss", "api", "figma",  "typescript", "jira", "redux", "aws", "git", "stripe", "paypal", "googlecloud",  "bitbucket"]}
                projectDesc="Sell & Purchase tutorial videos | With 2 Payment Gateways"
                device="pc"
                deviceImage={[tuto]}
                website="https://github.com/jatolentino/TutorialsPoint"
                projectUrl="https://github.com/jatolentino/TutorialsPoint"
                videoSource={null}
                tags={["website", "go", "next", "redux", "javascript", "payment"]}
                compareTag={compareTag}
            />
            <Project 
                title="Mediagram"
                stacks={["elixir", "phoenix", "react","graphql", "docker", "kubernetes",  "bootstrap", "api", "figma",  "javascript", "jira", "aws", "git",  "bitbucket"]}
                projectDesc="Social Media Platorm | For users"
                device="pc"
                deviceImage={[mediagram]}
                website="https://github.com/jatolentino/Mediagram"
                projectUrl="https://github.com/jatolentino/Mediagram"
                videoSource={null}
                tags={["website", "elixir", "react", "redux", "javascript"]}
                compareTag={compareTag}
            />
            
        </div>
    );
};

export default ProjectArea;
