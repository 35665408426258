const CVFirstignite = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1_7FKc80wKCmwZSUzHsxs4uc8CrtlyiocvK02jmrghDo&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVFirstignite




