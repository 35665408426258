const CVHapi = () =>  {
    return (
        <div>
            <iframe title="cv" className="doc" src="https://docs.google.com/viewer?srcid=1mOf13tpbh1jiLz8NujNgulR4ss5tva1eJsGLEgohN_U&pid=explorer&embedded=true"></iframe>
        </div>
    );
}
export default CVHapi




