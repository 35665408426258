import React from 'react';
// import './VimeoEmbed.css'; // Import the CSS file for styling

const Demo = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-black">
      <iframe
        src="https://player.vimeo.com/video/1034123294?badge=0&amp;&autoplay=1&amp;player_id=0&amp;app_id=58479"  // Replace with your iframe source
        title="Responsive Iframe"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        className="w-full h-full max-w-full aspect-video border-none"
        frameBorder="0"
      />
    </div>
  );
};

export default Demo;

{/* <iframe width="560" height="315" onLoad={handleIFrameLoad} src={videoSource} allow="autoplay; fullscreen; picture-in-picture; clipboard-write"  title={videoSource}></iframe> */}