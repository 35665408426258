import Sidebar from "../components/Sidebar/Sidebar";
import patent from "../assets/img/patent2.jpg";
import vtol from "../assets/img/vtol.jpg";
import cansat from "../assets/img/cansat-v3.jpg";

const Hardware = () => {
  return (
    <div className="flex h-screen w-screen  ">
      <Sidebar />
      <div className="max-w-7xl mx-auto my-8 px-2">
        <div className="flex justify-center text-2xl md:text-3xl font-bold mb-4 mt-10">
          HARDWARE
        </div>

        <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-2 p-2 xl:p-5 mt-10">
          <li className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-primary-400">
            <a className="relative" href="">
              <div className="relative w-full aspect-video">
                <img
                  className="rounded w-full object-cover"
                  src={patent}
                  alt="control-module"
                  loading="lazy"
                />
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-b from-gray-800 to-gray-500 text-white">
                  <h2 className="text-xl font-semibold">
                    {" "}
                    IoT Industrial Liquid Level Control System
                  </h2>
                  <p className="font-medium text-sm">Patent of Utility</p>
                </div>
              </div>
            </a>
            <div className="flex flex-col justify-beetween gap-3 px-4 py-2">
              <p className="text-gray-600 two-lines">
                This control apparatus employs a variety of shaped level tanks
                for various tests to control water level over each tank, and the
                control panel enables advanced algorithms implementation through
                programmable logic controllers (PLCs) and field-programmable
                gate arrays (FPGAs).
                <a href="https://github.com/jatolentino/BSc-Thesis" target="_blank" className="text-white ml-2 bg-gray-600 hover:bg-black py-1 px-4 rounded-lg text-sm">
                  <i className="fab fa-github "></i> Demo
                </a>
                <a href="https://jatolentino.github.io/BSc-Thesis/" target="_blank" className=" text-white ml-2 bg-[#008DDF] hover:bg-[#0175E4] py-1 px-4 rounded-lg text-sm">
                  <i className="fas fa-file-pdf"></i> Docs (ES)
                </a>
              </p>
              <ul className="flex flex-wrap items-center justify-start text-sm gap-2 mb-10">
                <li title="Pricing type">
                  <div className="flex flex-wrap gap-2">
                    <a href="#" className="bg-cyan-200 hover:bg-cyan-300 py-1 px-2 rounded-lg text-sm">
                      #Control
                    </a>
                    <a href="#" className="bg-green-200 hover:bg-green-300 py-1 px-2 rounded-lg text-sm">
                      #Kubernetes
                    </a>
                    <a href="#" className="bg-yellow-200 hover:bg-yellow-300 py-1 px-2 rounded-lg text-sm">
                      #AI
                    </a>
                    <a href="#" className="bg-indigo-200 hover:bg-indigo-300 py-1 px-2 rounded-lg text-sm">
                      #Altium
                    </a>
                    <a href="#" className="bg-purple-200 hover:bg-purple-300 py-1 px-2 rounded-lg text-sm">
                      #LabView
                    </a>
                    <a href="#" className="bg-pink-200 hover:bg-pink-300 py-1 px-2 rounded-lg text-sm">
                      #Matlab
                    </a>
                    <a href="#" className="bg-orange-200 hover:bg-orange-300 py-1 px-2 rounded-lg text-sm">
                      #FPGA
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>

          <li className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-primary-400">
            <a className="relative" href="">
              <div className="relative w-full aspect-video">
                <img className="rounded w-full object-cover" src={vtol} alt="control-module" loading="lazy"/>
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-b from-gray-800 to-gray-500 text-white">
                  <h2 className="text-xl font-semibold">
                    Electric Autonomous VTOL{" "}
                  </h2>
                  <p className="font-medium text-sm">Urban Transportation</p>
                </div>
              </div>
            </a>
            <div className="flex flex-col justify-beetween gap-3 px-4 py-2">
              <p className="text-gray-600 two-lines">
                This VTOL leverages Feedback Control (FC) and Model Predictive
                Control (MPC) to autonomously follow a desired trajectory. The
                system also incorporates a machine learning (ML) algorithm
                integrated into the feedback controller to enhance position
                tracking.
                <a href="https://github.com/jatolentino/Autonomous-VTOL" target="_blank" className="text-white ml-2 bg-gray-600 hover:bg-black py-1 px-4 rounded-lg text-sm">
                  <i className="fab fa-github "></i> Demo
                </a>
                <a href="https://ieeexplore.ieee.org/document/9708830" target="_blank" className=" text-white ml-2 bg-[#10669A] hover:bg-[#0175E4] py-1 px-4 rounded-lg text-sm">
                  <i className="fas fa-file-pdf"></i> IEEE
                </a>
              </p>
              <ul className="flex flex-wrap items-center justify-start text-sm gap-2 mb-10">
                <li title="Pricing type">
                  <div className="flex flex-wrap gap-2">
                    <a href="#" className="bg-cyan-200 hover:bg-cyan-300 py-1 px-2 rounded-lg text-sm">
                      #Control
                    </a>
                    <a
                      href="#" className="bg-yellow-200 hover:bg-yellow-300 py-1 px-2 rounded-lg text-sm">
                      #ML
                    </a>
                    <a href="#" className="bg-green-200 hover:bg-green-300 py-1 px-2 rounded-lg text-sm">
                      #Octave
                    </a>
                    <a href="#" className="bg-purple-200 hover:bg-purple-300 py-1 px-2 rounded-lg text-sm">
                      #Matlab
                    </a>
                    <a href="#" className="bg-indigo-200 hover:bg-indigo-300 py-1 px-2 rounded-lg text-sm">
                      #Solidworks
                    </a>
                    <a href="#" className="bg-pink-200 hover:bg-pink-300 py-1 px-2 rounded-lg text-sm">
                      #FPGA
                    </a>
                    <a href="#" className="bg-orange-200 hover:bg-orange-300 py-1 px-2 rounded-lg text-sm">
                      #Altium
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>

          <li className="relative bg-white flex flex-col justify-between border rounded shadow-md hover:shadow-primary-400">
            <a className="relative" href="">
              <div className="relative w-full aspect-video">
                <img className="rounded w-full object-cover" src={cansat} alt="control-module" loading="lazy" />
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-b from-gray-800 to-gray-500 text-white">
                  <h2 className="text-xl font-semibold">
                    CanSat
                  </h2>
                  <p className="font-medium text-sm">Picosatellite in a can</p>
                </div>
              </div>
            </a>
            <div className="flex flex-col justify-beetween gap-3 px-4 py-2">
              <p className="text-gray-600 two-lines">
              The CanSat project involves designing and building a miniaturized satellite housed within a soda can, launched via rocket, to collect atmospheric data such as temperature, pressure, and humidity, demonstrating satellite engineering principles and data transmission techniques.
                <a href="https://github.com/jatolentino/CanSat" target="_blank" className="text-white ml-2 bg-gray-600 hover:bg-black py-1 px-4 rounded-lg text-sm">
                  <i className="fab fa-github "></i> Project
                </a>
              </p>
              <ul className="flex flex-wrap items-center justify-start text-sm gap-2 mb-10">
                <li title="Pricing type">
                  <div className="flex flex-wrap gap-2">
                    <a href="#" className="bg-cyan-200 hover:bg-cyan-300 py-1 px-2 rounded-lg text-sm">
                      #C
                    </a>
                    <a href="#" className="bg-green-200 hover:bg-green-300 py-1 px-2 rounded-lg text-sm">
                      #ARM
                    </a>
                    <a href="#" className="bg-yellow-200 hover:bg-yellow-300 py-1 px-2 rounded-lg text-sm">
                      #PCB
                    </a>
                    <a href="#" className="bg-indigo-200 hover:bg-indigo-300 py-1 px-2 rounded-lg text-sm">
                      #Altium
                    </a>
                    <a href="#" className="bg-purple-200 hover:bg-purple-300 py-1 px-2 rounded-lg text-sm">
                      #Solidworks
                    </a>
                    
                    <a href="#" className="bg-orange-200 hover:bg-orange-300 py-1 px-2 rounded-lg text-sm">
                      #Telemetry
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>

          

        </ul>
      </div>
    </div>
  );
};

export default Hardware;
